import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API, MESSAGES, ROUTERLINKS } from '../../../constants/index';
import { CommunicationService } from '../core/communication.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class OrganizationService {

  growlData: any = {};

  constructor(private dataService: DataService, private _myCommunicationService: CommunicationService,
    private _cookieService: CookieService, private router: Router) { }

  addNewOrganization(organization) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.ADD_NEW_ORG, organization);
  }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ORG, req);
  }

  getOrgDataById(id) {
    const req = {
      id: id,
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ORG_BY_ID, req);
  }

  getOrgTheme() {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_ORG_THEME);
  }

  update(organization) {
    const orgObj = organization;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ORG_BY_ID, orgObj);
  }

  delete(id) {
    const req = {
      id: id,
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ORG_BY_ID, req);
  }

  getAllUpcoming(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_UPCOMINGS, req);
  }

  orgOnboardLogout() {
    this._cookieService.remove('loggedInUser');
    localStorage.removeItem('moduleList');
    this._cookieService.remove('roles');
    this._cookieService.remove('certificate');
    this.router.navigate([ROUTERLINKS.LOGIN]);
    this.growlData = {
      severity: 'success', summary: MESSAGES.ORGONBOARD_LOGOUT, detail: ''
    };
    this._myCommunicationService.emitChange(this.growlData);
  }

  // location APIS
  getAllLocation() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_LOCATIONS, req);
  }
  createLocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_LOCATION, req);
  }

  getAllModules() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_MODULES, req);
  }

  getOrgWiseModules(orgId) {
    const req = {
      orgId: orgId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ORG_WISE_MODULES, req);
  }

  assignModules(id, modId) {
    const req = {
      orgId: id, moduleId: modId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ASSIGN_ORG_WISE_MODULES, req);
  }

  deleteModules(id, modId) {
    const req = {
      orgId: id, moduleId: modId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ORG_WISE_MODULES, req);
  }

  addAdmin(id) {
    const req = {
      userId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_ADMIN, req);
  }

  removeAdmin(id) {
    const req = {
      userId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REMOVE_ADMIN, req);
  }

  checkModuleEnabledForOrg(orgId, moduleId) {
    const req = {
      orgId: orgId,
      moduleId: moduleId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CHECK_MODULE_ENABLED_FOR_ORG, req);
  }
}
