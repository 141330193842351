import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  private primaryColorSource = new BehaviorSubject<string>(null); // Emits the primary color
  primaryColor$ = this.primaryColorSource.asObservable();

  updatePrimaryColor(color: string): void {
    this.primaryColorSource.next(color);
  }
}
