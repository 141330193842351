import { Injectable } from '@angular/core';


declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {

  recognition: any;
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords;

  constructor() { }

  init() {
    if ('webkitSpeechRecognition' in window) {
      this.recognition = new webkitSpeechRecognition();
    } else {
      console.error("Speech recognition not supported in this browser.");
    }

    if (this.recognition) {
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';
      this.recognition.addEventListener('result', (e) => {
        const transcript = Array.from(e.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join('');
        this.tempWords = transcript;
        var element = (<HTMLInputElement>document.getElementById('text-input'));
        element.value = transcript;
      });
    }
  }

  start() {
    this.isStoppedSpeechRecog = false;
    if (this.recognition) {
      this.recognition.start();
      this.recognition.addEventListener('end', (condition) => {
        if (this.isStoppedSpeechRecog) {
          this.recognition.stop();
        } else {
          this.wordConcat()
          this.recognition.start();
        }
      });
    }
  }

  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat();
    // console.log("stop outside");
    // if(this.recognition){
    // console.log("stop");
    this.recognition.stop();
    // }
  }

  wordConcat() {
    this.text = this.text + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }
}
