export class Organization {
  orgName: string;
  orgUrl: string;
  orgEmailDomain: string;
  noOfUsersAllowed: number;
  authenticationType: number;
  authenticationUrl: string;
  adminUserName: string;
  adminFirstName: string;
  adminLastName: string;
  adminPhone: string;
  dailyWorkHours: number;
  weeklyWorkDays: number;
  pmoEmail: string;
  pmoCheck: boolean;
  reportToAssignedProj: boolean;
  orgTheme: {
    primary: string;
  } = {
      primary: ""
    };
}
